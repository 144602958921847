import { ObjectID } from "bson";
import { pick } from "lodash";
import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

import {
	OrderItemLike,
	applyServiceChargeToOrder,
	applyVatToOrder,
} from "~served/utils";

import { getCurrentLocationCache } from "~/queries/useGetCurrentLocation";
import { getCurrentVenueCache } from "~/queries/useGetCurrentVenue";
import {
	CHECKOUT_WITH_PAY_WAY_PAYMENT_OPTION,
	CreateOrderItemInput,
} from "~/types/__generated/gql/graphql";
import { Actions, State } from "~/types/store";
import { createSelectors } from "~/types/utils";

export const ALL_CATEGORY = "all";

const INITIAL_STATE: State = {
	error: null,
	info: null,
	isSessionExpired: false,
	isReady: false,
	isConnectedToServer: true,
	isLatest: true,
	isNeededHelp: false,
	token: null,
	venueId: null,
	locationId: null,
	menuId: null,
	orderRef: null,
	orderId: null,
	deviceId: null,
	cart: {
		customer: undefined,
		payment_types: [],
		items: [],
	},
	paywayPaymentType: CHECKOUT_WITH_PAY_WAY_PAYMENT_OPTION.abapay,
	search: "",
	selectedCategoryId: ALL_CATEGORY,
};

export const useStore = create<State & Actions>()(
	persist(
		(set, get) => ({
			...INITIAL_STATE,
			setIsSessionExpired: (data) => set({ isSessionExpired: data }),
			setError: (data) => set({ error: data }),
			setInfo: (data) => set({ info: data }),
			setIsReady: (data) => set({ isReady: data }),
			setIsConnectedToServer: (data) => set({ isConnectedToServer: data }),
			setIsLatest: (data) => set({ isLatest: data }),
			setIsNeededHelp: (data) => set({ isNeededHelp: data }),
			setDeviceId: (data) => set({ deviceId: data }),
			updateCart: async (data) => {
				const newCart = { ...get().cart, ...data };

				const venue = await getCurrentVenueCache();
				const location = await getCurrentLocationCache();
				if (!location || !venue)
					throw new Error("Invalid venue or location data");

				const vat = location.no_vat ? 0 : venue.vat;
				const serviceCharge = location.no_service_charge
					? 0
					: venue.service_charge;

				const { items: itemsWithVat } = applyVatToOrder({
					order: { items: newCart.items },
					vat,
				});
				const { items: itemsWithServiceCharge } = applyServiceChargeToOrder({
					order: { items: itemsWithVat },
					serviceCharge,
				});
				newCart.items = itemsWithServiceCharge as CreateOrderItemInput[];

				set({ cart: newCart });
			},
			addItem: async (item) => {
				const prevItems = get().cart.items;
				const itemInCart = prevItems.find(
					(ci) => ci.item === item._id && !ci.options.length,
				);

				const newItems = itemInCart
					? prevItems.map((ci) =>
							ci._id === itemInCart._id
								? { ...itemInCart, quantity: itemInCart.quantity + 1 }
								: ci,
						)
					: [
							...prevItems,
							{
								...pick(item, [
									"item_id",
									"cost",
									"title",
									"prep_time",
									"type",
									"original_price",
									"minimum_required_price",
									"original_price_addons",
									"listed_price",
									"extra_quantity",
									"category",
									"printer_tag",
									"no_vat",
									"no_service_charge",
									"is_open_item",
									"is_hide_from_receipt",
								]),
								_id: new ObjectID().toString(),
								item: item._id,
								recipe: item.recipe,
								quantity: 1,
								options: [],
							} as OrderItemLike,
						];

				const venue = await getCurrentVenueCache();
				const location = await getCurrentLocationCache();

				if (!location || !venue)
					throw new Error("Invalid venue or location data");

				const vat = location.no_vat ? 0 : venue.vat;
				const serviceCharge = location.no_service_charge
					? 0
					: venue.service_charge;

				const { items: itemsWithVat } = applyVatToOrder({
					order: { items: newItems },
					vat,
				});
				const { items: itemsWithServiceCharge } = applyServiceChargeToOrder({
					order: { items: itemsWithVat },
					serviceCharge,
				});
				set((s) => ({
					cart: {
						...s.cart,
						items: itemsWithServiceCharge as CreateOrderItemInput[],
					},
				}));
			},
			removeItem: async (item) => {
				const prevItems = get().cart.items;
				const itemInCart =
					prevItems.find((ci) => ci.item === item._id && !ci.options.length) ??
					prevItems.find((ci) => ci.item === item._id);

				if (!itemInCart) return;

				const newItems = prevItems
					.map((ci) =>
						ci._id === itemInCart._id
							? { ...itemInCart, quantity: itemInCart.quantity - 1 }
							: ci,
					)
					.filter((i) => i.quantity);

				const venue = await getCurrentVenueCache();
				const location = await getCurrentLocationCache();
				if (!location || !venue)
					throw new Error("Invalid venue or location data");

				const vat = location.no_vat ? 0 : venue.vat;
				const serviceCharge = location.no_service_charge
					? 0
					: venue.service_charge;

				const { items: itemsWithVat } = applyVatToOrder({
					order: { items: newItems },
					vat,
				});
				const { items: itemsWithServiceCharge } = applyServiceChargeToOrder({
					order: { items: itemsWithVat },
					serviceCharge,
				});
				set((s) => ({
					cart: {
						...s.cart,
						items: itemsWithServiceCharge as CreateOrderItemInput[],
					},
				}));
			},
			setPaywayPaymentType: (data) => set({ paywayPaymentType: data }),
			resetCart: () => set({ cart: INITIAL_STATE.cart }),
			resetApp: () => set(INITIAL_STATE),
			setSearch: (data) => set({ search: data }),
			setSelectedCategoryId: (data) => set({ selectedCategoryId: data }),
		}),
		{
			name: "served-consumer-app-store",
			storage: createJSONStorage(() => window.sessionStorage),
		},
	),
);

export const {
	use: useSelector,
	setState,
	getState,
} = createSelectors(useStore);

export const useError = () => {
	return useSelector.error();
};
export const useSetError = () => {
	return useSelector.setError();
};
export const useInfo = () => {
	return useSelector.info();
};
export const useSetInfo = () => {
	return useSelector.setInfo();
};
export const useIsReady = () => {
	return useSelector.isReady();
};
export const useSetIsReady = () => {
	return useSelector.setIsReady();
};
export const useIsConnectedToServer = () => {
	return useSelector.isConnectedToServer();
};
export const useSetIsConnectedToServer = () => {
	return useSelector.setIsConnectedToServer();
};
export const useIsLatest = () => {
	return useSelector.isLatest();
};
export const useSetIsLatest = () => {
	return useSelector.setIsLatest();
};
export const useIsNeededHelp = () => {
	return useSelector.isNeededHelp();
};
export const useSetIsNeededHelp = () => {
	return useSelector.setIsNeededHelp();
};
export const useToken = () => {
	return useSelector.token();
};
export const useVenueId = () => {
	return useSelector.venueId();
};
export const useLocationId = () => {
	return useSelector.locationId();
};
export const useMenuId = () => {
	return useSelector.menuId();
};
export const useOrderRef = () => {
	return useSelector.orderRef();
};
export const useOrderId = () => {
	return useSelector.orderId();
};
export const useDeviceId = () => {
	return useSelector.deviceId();
};
export const useSetDeviceId = () => {
	return useSelector.setDeviceId();
};
export const useUpdateCart = () => {
	return useSelector.updateCart();
};
export const useAddItem = () => {
	return useSelector.addItem();
};
export const useRemoveItem = () => {
	return useSelector.removeItem();
};
export const useCart = () => {
	return useSelector.cart();
};
export const useResetCart = () => {
	return useSelector.resetCart();
};
export const usePaywayPaymentType = () => {
	return useSelector.paywayPaymentType();
};
export const useSetPaywayPaymentType = () => {
	return useSelector.setPaywayPaymentType();
};
export const useSetIsSessionExpired = () => {
	return useSelector.setIsSessionExpired();
};
export const useIsSessionExpired = () => {
	return useSelector.isSessionExpired();
};
export const useResetApp = () => {
	return useSelector.resetApp();
};
export const useCartItems = () => {
	return useStore((s) => s.cart.items);
};
