import { useQuery } from "@tanstack/react-query";
import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import {
	GetActiveMenuItemQuery,
	GetActiveMenuItemQueryVariables,
} from "~/types/__generated/gql/graphql";
import { sortByOrderingIndex } from "~/utils/sortByOrderingIndex";

const query = /* GraphQL */ `
	query GetActiveMenuItem($id: ObjectID!) {
		getActiveMenuItem(id: $id) {
			_id
			created_at
			updated_at
			item_id
			cost
			title
			description
			banner_img
			is_qr_enabled
			is_pos_enabled
			no_vat
			no_service_charge
			is_open_item
			is_hide_from_receipt
			type
			prep_time
			is_template
			clone_from
			ordering_index

			extra_quantity
			printer_tag
			original_price
			minimum_required_price
			original_price_addons {
				vat {
					percentage
					amount
				}
				service_charge {
					percentage
					amount
				}
			}
			listed_price
			category
			menu
			venue
			recipe {
				raw_material
				unit_of_measurement
				consumed_unit_of_measurement
				consumed_quantity
			}
			options {
				ordering_index
				is_hide_from_receipt
				_option {
					_id
					created_at
					updated_at
					option_id
					cost
					title
					is_template
					clone_from

					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					listed_price
					menu
					venue
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
				}
			}
			option_groups {
				is_required
				minimum_select
				maximum_select
				ordering_index
				options {
					ordering_index
					is_hide_from_receipt
					_option {
						_id
						created_at
						updated_at
						option_id
						cost
						title
						is_template
						clone_from

						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						menu
						venue
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
					}
				}
				_option_group {
					_id
					title
					options {
						ordering_index
						_option {
							_id
							created_at
							updated_at
							option_id
							cost
							title
							is_template
							clone_from

							original_price
							original_price_addons {
								vat {
									percentage
									amount
								}
								service_charge {
									percentage
									amount
								}
							}
							listed_price
							menu
							venue
							recipe {
								raw_material
								unit_of_measurement
								consumed_unit_of_measurement
								consumed_quantity
							}
						}
					}
					clone_from
				}
			}
		}
	}
`;

export type GetActiveMenuItemResults =
	GetActiveMenuItemQuery["getActiveMenuItem"];
export type GetActiveMenuItemVariables = GetActiveMenuItemQueryVariables;

export const getActiveMenuItemQueryKey = (
	variables: GetActiveMenuItemVariables,
) => ["getActiveMenuItem", variables];

export const getActiveMenuItemQueryFn = async (
	variables: GetActiveMenuItemVariables,
) =>
	hubGqlClient.request(graphql(query), variables).then((res) => {
		const item = res.getActiveMenuItem;
		return {
			...item,
			options: item.options.sort(sortByOrderingIndex),
			option_groups: item.option_groups.sort(sortByOrderingIndex).map((g) => ({
				...g,
				options: g.options.sort(sortByOrderingIndex),
			})),
		};
	});

export const useGetActiveMenuItem = (
	variables: Partial<GetActiveMenuItemVariables>,
) => {
	return useQuery({
		enabled: !!variables.id,
		queryKey: getActiveMenuItemQueryKey({
			...variables,
		} as GetActiveMenuItemVariables),
		queryFn: () =>
			getActiveMenuItemQueryFn({ ...variables } as GetActiveMenuItemVariables),
	});
};

export const getActiveMenuItemCache = async (
	variables: GetActiveMenuItemVariables,
) => {
	const cached = window.$queryClient?.getQueryData<GetActiveMenuItemResults>(
		getActiveMenuItemQueryKey(variables),
		{ exact: true },
	);
	if (cached !== undefined) return cached;

	await window.$queryClient?.fetchQuery({
		queryKey: getActiveMenuItemQueryKey(variables),
		queryFn: () => getActiveMenuItemQueryFn(variables),
	});
	return window.$queryClient?.getQueryData<GetActiveMenuItemResults>(
		getActiveMenuItemQueryKey(variables),
		{ exact: true },
	);
};
