import { html } from "common-tags";

export interface GetStyleFragmentParameters {
	print_content_padding: number[];
	print_font_sizes: {
		ticket: number;
		receipt: number;
		reports: number;
	};
}

export const getStyleFragment = (
	{ print_content_padding, print_font_sizes }: GetStyleFragmentParameters,
	printType: keyof GetStyleFragmentParameters["print_font_sizes"],
) => html`
	<link
		rel="preconnect"
		href="https://fonts.googleapis.com" />
	<link
		rel="preconnect"
		href="https://fonts.gstatic.com"
		crossorigin />
	<link
		href="https://fonts.googleapis.com/css2?family=Battambang:wght@100;300;400;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
		rel="stylesheet" />

	<style>
		body {
			width: 300px;
			background: white;
			font-size: ${print_font_sizes[printType]}px;
			font-family: "Battambang", cursive;
			font-family: "Roboto", sans-serif;
		}

		.container {
			padding: ${print_content_padding.map((p) => `${p}px`).join(" ")};
		}

		.centered {
			text-align: center;
		}

		.text-align-right {
			text-align: right;
		}

		.line-height-1 {
			line-height: 1;
		}

		.divider {
			border: 0.5px solid;
		}

		.ticket-linebreak {
			border: 0.5px dashed;
		}

		.full-width {
			width: 100%;
		}

		.display-flex {
			display: flex;
		}

		.flex-direction-column {
			flex-direction: column;
		}

		.justify-content-between {
			justify-content: space-between;
		}

		.row {
			display: flex;
		}

		.row .col-qty {
			width: 10%;
		}

		.row .col-item {
			width: 70%;
		}

		.row .col-price {
			text-align: right;
			padding-right: 8px;
			width: 20%;
		}

		.bold {
			font-weight: 800;
		}

		h4 {
			line-height: 0px;
		}

		.text-2sm {
			font-size: 70%;
		}

		.text-sm {
			font-size: 85%;
		}

		.text-md {
			font-size: 100%;
		}

		.text-lg {
			font-size: 125%;
		}

		.text-xl {
			font-size: 150%;
		}

		.text-2xl {
			font-size: 200%;
		}

		.light-bold {
			font-weight: 600;
		}

		.float-right {
			float: right;
		}

		.my-1 {
			margin-top: 4px;
			margin-bottom: 4px;
		}

		.row .col-qty-ticket {
			width: 10%;
		}

		.row .col-item-ticket {
			width: 70%;
		}

		.row .col-item-full-width-ticket {
			width: 90%;
		}

		.row .col-price-ticket {
			text-align: right;
			padding-right: 8px;
			width: 20%;
		}

		.italic {
			font-style: italic;
		}

		.row .col-count-reports {
			width: 12.5%;
		}

		.row .col-title-reports {
			width: 50%;
		}

		.row .col-revenue-reports {
			width: 20.8%;
		}

		.row .col-revenue-percentage-reports {
			width: 16.7%;
		}

		.mt-1 {
			margin-top: 4px;
		}

		.pr-2 {
			padding-right: 8px;
		}

		#logo {
			width: 150px;
			height: 150px;
			object-fit: cover;
			object-position: center;
		}
	</style>
`;
