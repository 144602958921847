import { useQuery } from "@tanstack/react-query";
import { hubGqlClient } from "~/libs/gql";
import { setState, useLocationId } from "~/store";
import { graphql } from "~/types/__generated/gql";
import { GetActiveMenuQuery } from "~/types/__generated/gql/graphql";
import { validateSessionFromUrl } from "~/utils/validateSessionFromUrl";

const query = /* GraphQL */ `
	query GetActiveMenu {
		getActiveMenu {
			_id
			created_at
			updated_at
			name
			description
			banner_img
		}
	}
`;

export type GetActiveMenuResults = GetActiveMenuQuery["getActiveMenu"];

export const getActiveMenuQueryKey = () => ["menu"];

export const useGetActiveMenu = (enabled?) => {
	const locationId = useLocationId();

	return useQuery({
		enabled: enabled ?? true,
		queryKey: getActiveMenuQueryKey(),
		queryFn: () => {
			const { isSessionExpired, isPathnameSessionSensitive } =
				validateSessionFromUrl();

			if ((isPathnameSessionSensitive() && isSessionExpired()) || !locationId)
				return Promise.resolve(undefined);

			return hubGqlClient
				.request(graphql(query))
				.then((res) => res.getActiveMenu);
		},
		onSuccess: (data) => {
			if (!data) return;

			setState({ menuId: data._id });
		},
	});
};

export const useGetActiveMenuCache = () => {
	return useGetActiveMenu(false);
};
