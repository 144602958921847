import { AppProps } from "next/app";
import Router from "next/router";
import { useEffect, useState } from "react";
import { ErrorBoundary, SplashScreen } from "~served/ui-comps";
import { AppUpdateChecker } from "~/components/AppUpdateChecker";
import { AssistanceDialog } from "~/components/AssistanceDialog";
import { ErrorAlert } from "~/components/ErrorAlert";
import { InfoAlert } from "~/components/InfoAlert";
import { OfflineDialog } from "~/components/OfflineDialog";
import { PushNotification } from "~/components/PushNotification";
import { SessionTrackerDialog } from "~/components/SessionTrackerDialog";
import { useRouterEvent } from "~/libs/google-analytics";
import { AppProvider } from "~/providers/AppProvider";
import { QueryProvider } from "~/providers/QueryProvider";
import "~/store";
import { useIsReady } from "~/store";
export default function MyApp({
  Component,
  pageProps
}: AppProps) {
  useServiceWorker();
  useRouterEvent();
  const isReady = useIsReady();
  const isMounted = useIsMounted();
  if (!isMounted) return null;
  return <ErrorBoundary appName="CONSUMER APP" id="1239414865336991766" token="i8lKTnR7kUSCmzu2PpX56oiSFOnN6gfG_KpeCTbYP-Oz2OYZXyxoEBBKQ7YtUV3PE7-_" data-sentry-element="ErrorBoundary" data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
			<QueryProvider data-sentry-element="QueryProvider" data-sentry-source-file="_app.tsx">
				<AppProvider data-sentry-element="AppProvider" data-sentry-source-file="_app.tsx">
					<OfflineDialog data-sentry-element="OfflineDialog" data-sentry-source-file="_app.tsx" />
					<AssistanceDialog data-sentry-element="AssistanceDialog" data-sentry-source-file="_app.tsx" />
					<InfoAlert data-sentry-element="InfoAlert" data-sentry-source-file="_app.tsx" />
					<ErrorAlert data-sentry-element="ErrorAlert" data-sentry-source-file="_app.tsx" />
					<SessionTrackerDialog data-sentry-element="SessionTrackerDialog" data-sentry-source-file="_app.tsx" />
					<AppUpdateChecker data-sentry-element="AppUpdateChecker" data-sentry-source-file="_app.tsx" />
					<PushNotification data-sentry-element="PushNotification" data-sentry-source-file="_app.tsx" />
					{isReady ? <Component {...pageProps} /> : <SplashScreen />}
				</AppProvider>
			</QueryProvider>
		</ErrorBoundary>;
}
disableReactDevTools();
const useIsMounted = () => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted;
};
const useServiceWorker = () => {
  useEffect(() => {
    const onReceiveServiceWorkerMessage = (event: MessageEvent) => {
      if (!event.data.action) return;
      switch (event.data.action) {
        case "redirect-from-notificationclick":
          void Router.replace(event.data.url);
          break;
      }
    };
    if (navigator.serviceWorker) navigator.serviceWorker.addEventListener("message", onReceiveServiceWorkerMessage);
    return () => {
      if (navigator.serviceWorker) navigator.serviceWorker.removeEventListener("message", onReceiveServiceWorkerMessage);
    };
  }, []);
};
function disableReactDevTools() {
  const isFunction = (obj: unknown) => {
    return typeof obj === "function";
  };
  const isObject = (obj: unknown) => {
    const type = typeof obj;
    return type === "function" || type === "object" && !!obj;
  };
  const hasWindowObject = () => {
    return typeof window !== "undefined" && !!window.document;
  };
  if (hasWindowObject()) {
    window.DEBUG_REACT_DEV_TOOLS_ENABLED = false;
  }
  const isEnabledDevTools = process.env.NODE_ENV === "development";
  if (isEnabledDevTools) {
    if (hasWindowObject()) window.DEBUG_REACT_DEV_TOOLS_ENABLED = true;
    return;
  }
  if (hasWindowObject()) {
    if (!isObject(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
      return;
    }
    for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
      if (prop === "renderers") {
        window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
        continue;
      }
      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = isFunction(window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop]) ? Function.prototype : null;
    }
  }
}
declare global {
  interface Window {
    DEBUG_REACT_DEV_TOOLS_ENABLED: boolean;
    __REACT_DEVTOOLS_GLOBAL_HOOK__: any;
  }
}