import { useQuery } from "@tanstack/react-query";
import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import { GetActiveMenuCategoriesQuery } from "~/types/__generated/gql/graphql";
import { sortByOrderingIndex } from "~/utils/sortByOrderingIndex";

const query = /* GraphQL */ `
	query GetActiveMenuCategories {
		getActiveMenuCategories {
			_id
			created_at
			updated_at
			title
			is_template
			clone_from
			ordering_index
		}
	}
`;

export type GetActiveMenuCategoriesResults =
	GetActiveMenuCategoriesQuery["getActiveMenuCategories"];

export const getActiveMenuCategoriesQueryKey = () => [
	"getActiveMenuCategories",
];

export const useGetActiveMenuCategories = (enabled?: boolean) => {
	return useQuery({
		enabled,
		queryKey: getActiveMenuCategoriesQueryKey(),
		queryFn: () =>
			hubGqlClient
				.request(graphql(query))
				.then((res) => res.getActiveMenuCategories.sort(sortByOrderingIndex)),
	});
};
