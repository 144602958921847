import { Client } from "graphql-ws";
import Router from "next/router";
import { setState } from "~/store";
import {
	DATA_EVENT_TYPE,
	SubscribeToLocationSubscription,
} from "~/types/__generated/gql/graphql";
import {
	GetCurrentLocationResults,
	getCurrentLocationQueryKey,
} from "../useGetCurrentLocation";

const query = /* GraphQL */ `
	subscription SubscribeToLocation($id: ObjectID!) {
		subscribeToLocation(id: $id) {
			type
			id
			payload {
				_id
				created_at
				updated_at
				venue
				name
				type
				payment_types
				status
				hash
				no_vat
				no_service_charge
				is_pay_later_allowed
				is_pay_now_allowed
				locations_groups_tags
				room_number
				default_order_note
				hidden_items_configs {
					items
					item_types
					categories
					is_items_hide_on_qr
					is_categories_hide_on_qr
					is_item_types_hide_on_qr
				}
			}
		}
	}
`;

export const subscribeToLocation = async (
	client: Client,
	locationId: string,
) => {
	const subscription = client.iterate<SubscribeToLocationSubscription>({
		query,
		variables: { id: locationId },
	});
	for await (const result of subscription) {
		if (
			result.data?.subscribeToLocation?.type !== DATA_EVENT_TYPE.delete &&
			result.data?.subscribeToLocation?.payload
		) {
			const payload = result.data.subscribeToLocation.payload;

			window.$queryClient?.setQueryData<GetCurrentLocationResults>(
				getCurrentLocationQueryKey(),
				(prev) => {
					// A hack to check if the location is updated, because this is also triggered when order is created
					const isUpdated =
						!prev ||
						Object.keys(payload).some(
							(key) =>
								JSON.stringify(payload[key] || {}) !==
								JSON.stringify(prev[key] || {}),
						);
					if (isUpdated) {
						setState((prev) => ({ cart: { ...prev.cart, items: [] } }));
						void Router.replace({ pathname: "/menu", query: Router.query });
					}

					return payload;
				},
			);
		}
	}
};
