import { html } from "common-tags";

import {
	PaymentsSummary,
	QuerygetPrintableReportsOverallArgs,
	SalesSummary,
	SalesSummaryByItems,
	SalesSummaryByLocations,
} from "~served/types/gql";

import locales from "../locales/locales.json";
import { bigMath } from "../misc";
import { getPaymentTitle } from "../order";
import {
	toVenueTime,
	venueDataHelper,
	VenueDataHelperParameter,
} from "../venue";

import {
	getStyleFragment,
	GetStyleFragmentParameters,
} from "./fragments/get-style-fragment";

export type SUPPORTED_LANGUAGE = "en" | "zh";

type GenerateOverallReportsOpts = Pick<
	QuerygetPrintableReportsOverallArgs,
	"from" | "to" | "shift"
> & {
	salesSummaryOverall: SalesSummary;
	itemsSummary: SalesSummaryByItems;
	locationsSummary: SalesSummaryByLocations;
	receiptsSummary: PaymentsSummary;
	venue: GetStyleFragmentParameters &
		VenueDataHelperParameter & {
			timezone: string;
			name: string;
			should_aggregate_tips_into_total_gross_sales: boolean;
		};
	selectedPaymentTypes: string[];
	selectedLocationGroupTags: string[];
	onlyItemsWithSales: boolean;
	language?: SUPPORTED_LANGUAGE;
	staff_name: string;
	printed_time: string;
};

const generateOverallReportsTemplate = ({
	from,
	to,
	shift,
	salesSummaryOverall,
	itemsSummary,
	locationsSummary,
	receiptsSummary,
	venue,
	selectedPaymentTypes,
	selectedLocationGroupTags,
	onlyItemsWithSales,
	language,
	staff_name,
	printed_time,
}: GenerateOverallReportsOpts) => {
	const { $d } = venueDataHelper(venue);

	const filteredPaymentTypes = Object.keys(receiptsSummary.byPaymentTypes)
		.filter((k) => selectedPaymentTypes.includes(k))
		.filter((k) =>
			onlyItemsWithSales ? !!receiptsSummary.byPaymentTypes[k] : true,
		);
	const filteredLocationGroupTags = locationsSummary.byLocationGroupTags
		.filter((summary) => selectedLocationGroupTags.includes(summary.tag))
		.filter((summary) =>
			onlyItemsWithSales ? !!summary.salesSummary.grossSales.total : true,
		);
	const filteredLocationGroupTagsSumUpAmount = filteredLocationGroupTags.reduce(
		(pre, cur) => bigMath.add(pre, cur.salesSummary.grossSales.total),
		0,
	);
	const filteredItemTypes = Object.keys(itemsSummary.grossSales.byTypes).filter(
		(k) => (onlyItemsWithSales ? !!itemsSummary.grossSales.byTypes[k] : true),
	);

	const localizedText = locales[language ?? "en"];

	return html`
		<html>
			<head>
				${getStyleFragment(venue, "reports")}
			</head>
			<body>
				<div class="container">
					<div class="centered text-xl light-bold my-1">
						${localizedText.salesSummaryReport}
					</div>
					<div class="centered light-bold my-1">${venue.name}</div>
					<br />
					<div class="text-sm my-1">${localizedText.staff}: ${staff_name}</div>
					<div class="text-sm my-1">
						${localizedText.printedTime}: ${toVenueTime(printed_time, venue)}
					</div>
					<div class="text-sm">
						${localizedText.dateFrom}:
						<span class="float-right pr-2"> ${toVenueTime(from, venue)} </span>
					</div>
					<div class="text-sm mt-1">
						${localizedText.dateTo}:
						<span class="float-right pr-2">${toVenueTime(to, venue)}</span>
					</div>
					${shift
						? `
                <div class="text-sm mt-1">
                  Selected Shift: <span class="float-right pr-2">${shift}</span>
                </div>
              `
						: ""}

					<br />

					<div class="text-md mt-1">
						${localizedText.salesSummary}
						<span class="float-right pr-2"
							>${$d(
								bigMath.add(
									salesSummaryOverall.grossSales.total,
									venue.should_aggregate_tips_into_total_gross_sales
										? receiptsSummary.tipsReceived
										: 0,
								),
							)}</span
						>
					</div>
					<hr class="divider" />
					<div class="text-sm">
						${localizedText.grossSales}:
						<span class="float-right pr-2"
							>${$d(salesSummaryOverall.grossSales.total)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.netSales}:
						<span class="float-right pr-2"
							>${$d(salesSummaryOverall.netSales.total)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.vat}:
						<span class="float-right pr-2"
							>${$d(salesSummaryOverall.vat.total)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.serviceCharge}:
						<span class="float-right pr-2"
							>${$d(salesSummaryOverall.serviceCharge.total)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.cardSurcharge}:
						<span class="float-right pr-2"
							>${$d(salesSummaryOverall.cardSurcharge.total)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.discount}:
						<span class="float-right pr-2"
							>${$d(salesSummaryOverall.discount.total)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.offer}:
						<span class="float-right pr-2"
							>${$d(salesSummaryOverall.offer.total)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.discountAndOffer}:
						<span class="float-right pr-2">
							${$d(
								bigMath.add(
									salesSummaryOverall.discount.total,
									salesSummaryOverall.offer.total,
								),
							)}
						</span>
					</div>
					<div class="text-sm">
						${localizedText.roundingAmt}:
						<span class="float-right pr-2"
							>${$d(receiptsSummary.roundingDifference)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.tips}:
						<span class="float-right pr-2"
							>${$d(receiptsSummary.tipsReceived)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.grossSales} + ${localizedText.tips}:
						<span class="float-right pr-2"
							>${$d(
								bigMath.add(
									salesSummaryOverall.grossSales.total,
									receiptsSummary.tipsReceived,
								),
							)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.headcount}:
						<span class="float-right pr-2">${receiptsSummary.headcount}</span>
					</div>

					<br />

					<div class="text-md mt-1">
						${localizedText.salesByReceipt}
						<span class="float-right pr-2"
							>${$d(
								bigMath.add(
									receiptsSummary.paymentsReceived,
									receiptsSummary.tipsReceived,
								),
							)}</span
						>
					</div>
					<hr class="divider" />
					<div class="text-sm">
						${localizedText.nonVoidReceipt} #:
						<span class="float-right pr-2"
							>${receiptsSummary.receiptsCount.nonVoid}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.voidReceipt} #:
						<span class="float-right pr-2"
							>${receiptsSummary.receiptsCount.void}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.averageReceiptValue}:
						<span class="float-right pr-2"
							>${$d(receiptsSummary.averagePaymentPerReceipt)}</span
						>
					</div>

					<br />

					<div class="text-md mt-1">
						${localizedText.salesByPaymentType}
						<span class="float-right pr-2"
							>${$d(
								bigMath.add(
									filteredPaymentTypes.reduce(
										(pre, cur) =>
											bigMath.add(
												pre,
												receiptsSummary.byPaymentTypes[cur] as
													| number
													| undefined,
											),
										0,
									),
									receiptsSummary.tipsReceived,
								),
							)}</span
						>
					</div>
					<hr class="divider" />
					${filteredPaymentTypes
						.map(
							(k) => `
                <div class="text-sm">
                  ${getPaymentTitle(k, venue)}:
									<span class="float-right pr-2">${$d(
										receiptsSummary.byPaymentTypes[k] as number,
									)}</span>
                </div>
              `,
						)
						.join("\n")}

					<br />

					<div class="text-md mt-1">
						${localizedText.salesByItemType}
						<span class="float-right pr-2"
							>${$d(salesSummaryOverall.grossSales.total)}</span
						>
					</div>
					<hr class="divider" />
					${filteredItemTypes
						.map(
							(k) => `
                <div class="text-sm">
                  ${k}: <span class="float-right pr-2">${$d(
										itemsSummary.grossSales.byTypes[k] as number,
									)}</span>
                </div>
              `,
						)
						.join("\n")}
					${locationsSummary.byLocationGroupTags.length
						? `
								<br />

								<div class="text-md mt-1">${localizedText.salesByLocaitonGroup}
								<span class="float-right pr-2">${$d(
									bigMath.add(
										filteredLocationGroupTagsSumUpAmount <=
											salesSummaryOverall.grossSales.total
											? filteredLocationGroupTagsSumUpAmount
											: salesSummaryOverall.grossSales.total,
										venue.should_aggregate_tips_into_total_gross_sales
											? receiptsSummary.tipsReceived
											: 0,
									),
								)}</span>
								</div>
								<hr class="divider" />
                ${filteredLocationGroupTags
									.map(
										(tSummary) => `
                      <div class="text-sm">
                        ${tSummary._tag.label}:
												<span class="float-right pr-2">${$d(
													tSummary.salesSummary.grossSales.total,
												)}</span>
                      </div>
                    `,
									)
									.join("\n")}
              `
						: ""}

					<br />

					<div class="text-md mt-1">
						${localizedText.tips}
						<span class="float-right pr-2"
							>${$d(
								filteredPaymentTypes.reduce(
									(pre, cur) =>
										bigMath.add(
											pre,
											receiptsSummary.tipsByPaymentTypes[cur] as
												| number
												| undefined,
										),
									0,
								),
							)}</span
						>
					</div>
					<hr class="divider" />
					${filteredPaymentTypes
						.map(
							(k) => `
                <div class="text-sm">
                  ${getPaymentTitle(k, venue)}:
									<span class="float-right pr-2">${$d(
										(receiptsSummary.tipsByPaymentTypes[k] as
											| number
											| undefined) ?? 0,
									)}</span>
                </div>
              `,
						)
						.join("\n")}

					<br />

					<div class="text-md mt-1">
						${localizedText.headcount}
						<span class="float-right pr-2">${receiptsSummary.headcount}</span>
					</div>
					<hr class="divider" />
					<div class="text-sm">
						${localizedText.averageSpendPerHeadcount}:
						<span class="float-right pr-2"
							>${$d(
								bigMath.div(
									bigMath.add(
										salesSummaryOverall.grossSales.total,
										venue.should_aggregate_tips_into_total_gross_sales
											? receiptsSummary.tipsReceived
											: 0,
									),
									receiptsSummary.headcount,
								),
							)}</span
						>
					</div>

					<br />

					<div class="text-md mt-1">
						${localizedText.cancellations}
						<span class="float-right pr-2"
							>${$d(itemsSummary.cancelled.value)}</span
						>
					</div>
					<hr class="divider" />
					<div class="text-sm">
						${localizedText.itemsValue}:
						<span class="float-right pr-2"
							>${$d(itemsSummary.cancelled.value)}</span
						>
					</div>
					<div class="text-sm">
						${localizedText.itemsCount}:
						<span class="float-right pr-2"
							>${itemsSummary.cancelled.count}</span
						>
					</div>
				</div>
			</body>
		</html>
	`
		.replace(/<!--\s*?[^\s?[][\s\S]*?-->/g, "")
		.replace(/>\s*</g, "><")
		.trim();
};

export { generateOverallReportsTemplate };
