import { useInfiniteQuery } from "@tanstack/react-query";
import { hubGqlClient } from "~/libs/gql";
import { graphql } from "~/types/__generated/gql";
import {
	GetActiveMenuItemsQrQuery,
	GetActiveMenuItemsQrQueryVariables,
} from "~/types/__generated/gql/graphql";
import { sortByOrderingIndex } from "~/utils/sortByOrderingIndex";

const query = /* GraphQL */ `
	query GetActiveMenuItemsQr(
		$categoryId: ObjectID
		$name: String
		$type: String
		$limit: Int
		$skip: Int
	) {
		getActiveMenuItemsQr(
			categoryId: $categoryId
			name: $name
			type: $type
			limit: $limit
			skip: $skip
		) {
			total
			items {
				_id
				created_at
				updated_at
				item_id
				cost
				title
				description
				banner_img
				is_qr_enabled
				is_pos_enabled
				no_vat
				no_service_charge
				is_open_item
				is_hide_from_receipt
				type
				prep_time
				is_template
				clone_from
				ordering_index
				extra_quantity
				printer_tag
				original_price
				minimum_required_price
				original_price_addons {
					vat {
						percentage
						amount
					}
					service_charge {
						percentage
						amount
					}
				}
				listed_price
				category
				menu
				venue
				recipe {
					raw_material
					unit_of_measurement
					consumed_unit_of_measurement
					consumed_quantity
				}
				options {
					ordering_index
					is_hide_from_receipt
				}
				option_groups {
					is_required
					minimum_select
					maximum_select
					ordering_index
					options {
						ordering_index
						is_hide_from_receipt
					}
				}
			}
		}
	}
`;

export type GetActiveMenuItemsQrResults =
	GetActiveMenuItemsQrQuery["getActiveMenuItemsQr"];
export type GetActiveMenuItemsQrVariables = GetActiveMenuItemsQrQueryVariables;

export const getActiveMenuItemsQrQueryKey = (
	variables: Omit<GetActiveMenuItemsQrVariables, "limit" | "skip">,
) => ["getActiveMenuItemsQr", variables];

const PAGE_SIZE = 20;

export const useGetActiveMenuItemsQrPaginated = (
	variables: Partial<Omit<GetActiveMenuItemsQrVariables, "limit" | "skip">>,
) => {
	return useInfiniteQuery({
		queryKey: getActiveMenuItemsQrQueryKey(variables),
		queryFn: ({ pageParam: _pageParam }) => {
			const pageParam = _pageParam ?? 1;

			return hubGqlClient
				.request(graphql(query), {
					...variables,
					limit: PAGE_SIZE,
					skip: (pageParam - 1) * PAGE_SIZE,
				})
				.then(({ getActiveMenuItemsQr: { total, items } }) => ({
					total,
					items: items.sort(sortByOrderingIndex),
				}));
		},
		getNextPageParam: (lastPage, pages) => {
			if (
				pages.reduce((pre, cur) => pre + cur.items.length, 0) === lastPage.total
			)
				return undefined;

			return pages.length + 1;
		},
		getPreviousPageParam: (_, pages) => {
			return pages.length < 2 ? undefined : pages.length - 1;
		},
	});
};
