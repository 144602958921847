import { Client } from "graphql-ws";
import Router from "next/router";
import { ALL_CATEGORY, getState, setState } from "~/store";
import {
	DATA_EVENT_TYPE,
	SubscribeToMenuSubscription,
} from "~/types/__generated/gql/graphql";
import {
	GetActiveMenuResults,
	getActiveMenuQueryKey,
} from "../useGetActiveMenu";
import {
	GetActiveMenuCategoriesResults,
	getActiveMenuCategoriesQueryKey,
} from "../useGetActiveMenuCategories";
import {
	GetActiveMenuItemsQrResults,
	GetActiveMenuItemsQrVariables,
	getActiveMenuItemsQrQueryKey,
} from "../useGetActiveMenuItemsQr";
import {
	getActiveMenuItemQueryKey,
	GetActiveMenuItemResults,
} from "../useGetActiveMenuItem";
import { sortByOrderingIndex } from "~/utils/sortByOrderingIndex";

const query = /* GraphQL */ `
	subscription SubscribeToMenu($id: ObjectID!) {
		subscribeToMenu(id: $id) {
			type
			id
			payload {
				menu {
					_id
					created_at
					updated_at
					name
					description
					banner_img
				}
				category {
					_id
					created_at
					updated_at
					title
					is_template
					clone_from
					ordering_index
				}
				item {
					_id
					created_at
					updated_at
					item_id
					cost
					title
					description
					banner_img
					is_qr_enabled
					is_pos_enabled
					no_vat
					no_service_charge
					is_open_item
					is_hide_from_receipt
					type
					prep_time
					is_template
					clone_from
					ordering_index
					extra_quantity
					printer_tag
					original_price
					minimum_required_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					listed_price
					category
					menu
					venue
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						ordering_index
						is_hide_from_receipt
						_option {
							_id
							created_at
							updated_at
							option_id
							cost
							title
							is_template
							clone_from
							original_price
							original_price_addons {
								vat {
									percentage
									amount
								}
								service_charge {
									percentage
									amount
								}
							}
							listed_price
							menu
							venue
							recipe {
								raw_material
								unit_of_measurement
								consumed_unit_of_measurement
								consumed_quantity
							}
						}
					}
					option_groups {
						is_required
						minimum_select
						maximum_select
						ordering_index
						options {
							ordering_index
							is_auto_select
							is_hide_from_receipt
							_option {
								_id
								created_at
								updated_at
								option_id
								cost
								title
								is_template
								clone_from
								original_price
								original_price_addons {
									vat {
										percentage
										amount
									}
									service_charge {
										percentage
										amount
									}
								}
								listed_price
								menu
								venue
								recipe {
									raw_material
									unit_of_measurement
									consumed_unit_of_measurement
									consumed_quantity
								}
							}
						}
						_option_group {
							_id
							title
							options {
								ordering_index
								_option {
									_id
									created_at
									updated_at
									option_id
									cost
									title
									is_template
									clone_from

									original_price
									original_price_addons {
										vat {
											percentage
											amount
										}
										service_charge {
											percentage
											amount
										}
									}
									listed_price
									menu
									venue
									recipe {
										raw_material
										unit_of_measurement
										consumed_unit_of_measurement
										consumed_quantity
									}
								}
							}
							clone_from
						}
					}
				}
			}
		}
	}
`;

export const subscribeToMenu = async (client: Client, menuId: string) => {
	const subscription = client.iterate<SubscribeToMenuSubscription>({
		query,
		variables: { id: menuId },
	});
	for await (const result of subscription) {
		const { search, selectedCategoryId } = getState();
		const variables: GetActiveMenuItemsQrVariables = {};
		if (search) variables.name = search;
		if (selectedCategoryId !== ALL_CATEGORY)
			variables.categoryId = selectedCategoryId;

		if (result.data?.subscribeToMenu?.payload?.menu) {
			window.$queryClient?.setQueryData<GetActiveMenuResults>(
				getActiveMenuQueryKey(),
				// @ts-ignore
				(prev) => {
					if (prev)
						return {
							...prev,
							...result.data!.subscribeToMenu.payload.menu,
						};
				},
			);
			void window.$queryClient?.refetchQueries<GetActiveMenuCategoriesResults>(
				getActiveMenuCategoriesQueryKey(),
				{
					exact: true,
				},
			);
			void window.$queryClient?.refetchQueries<GetActiveMenuItemsQrResults>(
				getActiveMenuItemsQrQueryKey(variables),
				{
					exact: true,
				},
			);
			setState((prev) => ({ cart: { ...prev.cart, items: [] } }));
			void Router.replace({ pathname: "/menu", query: Router.query });
		}

		if (result.data?.subscribeToMenu?.payload?.category) {
			void window.$queryClient?.refetchQueries(
				getActiveMenuItemsQrQueryKey(variables),
				{
					exact: true,
				},
			);
			window.$queryClient?.setQueryData<GetActiveMenuCategoriesResults>(
				getActiveMenuCategoriesQueryKey(),
				// @ts-ignore
				(prev) => {
					if (prev) {
						if (result.data!.subscribeToMenu.type === DATA_EVENT_TYPE.create) {
							return [
								...prev,
								result.data!.subscribeToMenu.payload.category!,
							].sort(sortByOrderingIndex);
						}
						if (result.data!.subscribeToMenu.type === DATA_EVENT_TYPE.update) {
							return prev.map((category) => {
								if (
									category._id ===
									result.data!.subscribeToMenu.payload.category!._id
								) {
									return {
										...category,
										...result.data!.subscribeToMenu.payload.category,
									};
								}
								return category;
							});
						}
						if (result.data!.subscribeToMenu.type === DATA_EVENT_TYPE.delete) {
							return prev.filter(
								(category) =>
									category._id !==
									result.data!.subscribeToMenu.payload.category!._id,
							);
						}
					}
				},
			);
			setState((prev) => ({ cart: { ...prev.cart, items: [] } }));
			void Router.replace({ pathname: "/menu", query: Router.query });
		}

		if (result.data?.subscribeToMenu?.payload?.item) {
			void window.$queryClient?.refetchQueries(
				getActiveMenuItemsQrQueryKey(variables),
				{
					exact: true,
				},
			);
			window.$queryClient?.setQueryData<GetActiveMenuItemResults>(
				getActiveMenuItemQueryKey({
					id: result.data.subscribeToMenu.payload.item._id,
				}),
				// @ts-ignore
				(prev) => {
					if (prev)
						return {
							...prev,
							...result.data!.subscribeToMenu.payload.item,
						};
				},
			);
			setState((prev) => ({ cart: { ...prev.cart, items: [] } }));
			void Router.replace({ pathname: "/menu", query: Router.query });
		}
	}
};
