import { UndefinedOrNull } from "~served/types/utils";
import { Offer } from "~served/types/db";

import { MongoDocument, OrderItemLike, OrderLike } from "../../calculation";
import { bigMath } from "../../misc";

type GenerateInvoiceTemplateParametersOrder = OrderLike & {
	note?: UndefinedOrNull<string>;
};

interface DuplicateItemResponse {
	isDuplicate: boolean;
	matchedIndex: number;
	returnItem: OrderItemLike;
}

export const getCombinedNotes = (
	orders: { note?: UndefinedOrNull<string> }[],
) =>
	orders
		.map((o) => o.note)
		.filter(Boolean)
		.join(", ");

export const getCombinedOffers = (items: OrderItemLike[]) =>
	items.reduce(
		(pre, cur) => {
			if (
				cur.subtotal_addons?.offer?.amount &&
				cur.subtotal_addons.offer.metadata?._id
			) {
				const newArray = [...pre];
				const offerIndex = newArray.findIndex(
					(p) =>
						p.metadata._id.toString() ===
						(cur.subtotal_addons!.offer.metadata as Offer)._id.toString(),
				);
				if (offerIndex !== -1) {
					newArray[offerIndex].amount = bigMath.add(
						newArray[offerIndex].amount,
						cur.subtotal_addons.offer.amount,
					);
				} else {
					newArray.push({
						metadata: cur.subtotal_addons.offer.metadata,
						amount: cur.subtotal_addons.offer.amount,
					});
				}

				return newArray;
			}

			return pre;
		},
		[] as { metadata: MongoDocument; amount: number }[],
	);

export const getTicketLinebreaksIndexDictionary: (obj: {
	ticket_linebreaks: number[];
}) => Record<number, number> = (obj) =>
	obj.ticket_linebreaks.reduce(
		(acc, linebreak) => ({ ...acc, [linebreak]: linebreak }),
		{},
	);

export const getCombinedOrdersItems = (
	orders: GenerateInvoiceTemplateParametersOrder[],
) => {
	const combinedItems: OrderItemLike[] = [];
	orders.map((o) => {
		o.items.map((item) => {
			const duplicate = getDuplicateItem(combinedItems, item);
			const { isDuplicate, matchedIndex, returnItem } = duplicate;
			if (isDuplicate && matchedIndex !== -1) {
				combinedItems[matchedIndex] = {
					...returnItem,
					quantity: returnItem.quantity + item.quantity,
					original_price_addons: {
						service_charge: {
							...returnItem.original_price_addons.service_charge,
							amount: bigMath.add(
								returnItem.original_price_addons.service_charge.amount,
								item.original_price_addons.service_charge.amount,
							),
						},
						vat: {
							...returnItem.original_price_addons.vat,
							amount: bigMath.add(
								returnItem.original_price_addons.vat.amount,
								item.original_price_addons.vat.amount,
							),
						},
					},
					subtotal_addons: {
						...returnItem._idsubtotal_addons,
						offer: {
							...returnItem.subtotal_addons?.offer,
							amount: bigMath.add(
								returnItem.subtotal_addons?.offer.amount ?? 0,
								item.subtotal_addons?.offer.amount ?? 0,
							),
						},
						discount: {
							...returnItem.subtotal_addons?.discount,
							amount: bigMath.add(
								returnItem.subtotal_addons?.discount.amount ?? 0,
								item.subtotal_addons?.discount.amount ?? 0,
							),
						},
					},
					subtotal: bigMath.add(returnItem.subtotal, item.subtotal),
					net_amount: bigMath.add(returnItem.net_amount, item.net_amount),
					gross_amount: bigMath.add(returnItem.gross_amount, item.gross_amount),
					cancelled_amount: bigMath.add(
						returnItem.cancelled_amount,
						item.cancelled_amount,
					),
				};
			} else {
				combinedItems.push(returnItem);
			}
		});
	});
	return combinedItems;
};

export const getCombinedOrdersCancelledItems = (
	orders: GenerateInvoiceTemplateParametersOrder[],
) => {
	const combinedItems: OrderItemLike[] = [];
	orders.map((o) => {
		o.cancelled_items?.map((item) => {
			const duplicate = getDuplicateItem(combinedItems, item);
			const { isDuplicate, matchedIndex, returnItem } = duplicate;
			if (isDuplicate && matchedIndex !== -1) {
				combinedItems[matchedIndex] = {
					...returnItem,
					quantity: returnItem.quantity + item.quantity,
					original_price_addons: {
						service_charge: {
							...returnItem.original_price_addons.service_charge,
							amount: bigMath.add(
								returnItem.original_price_addons.service_charge.amount,
								item.original_price_addons.service_charge.amount,
							),
						},
						vat: {
							...returnItem.original_price_addons.vat,
							amount: bigMath.add(
								returnItem.original_price_addons.vat.amount,
								item.original_price_addons.vat.amount,
							),
						},
					},
					subtotal_addons: {
						...returnItem._idsubtotal_addons,
						offer: {
							...returnItem.subtotal_addons?.offer,
							amount: bigMath.add(
								returnItem.subtotal_addons?.offer.amount ?? 0,
								item.subtotal_addons?.offer.amount ?? 0,
							),
						},
						discount: {
							...returnItem.subtotal_addons?.discount,
							value: bigMath.add(
								returnItem.subtotal_addons?.discount.value ?? 0,
								item.subtotal_addons?.discount.value ?? 0,
							),
						},
					},
					subtotal: bigMath.add(returnItem.subtotal, item.subtotal),
					net_amount: bigMath.add(returnItem.net_amount, item.net_amount),
					gross_amount: bigMath.add(returnItem.gross_amount, item.gross_amount),
					cancelled_amount: bigMath.add(
						returnItem.cancelled_amount,
						item.cancelled_amount,
					),
				};
			} else {
				combinedItems.push(returnItem);
			}
		});
	});
	return combinedItems;
};

const getDuplicateItem = (itemList: OrderItemLike[], item: OrderItemLike) => {
	const response: DuplicateItemResponse = {
		isDuplicate: false,
		matchedIndex: -1,
		returnItem: {} as unknown as OrderItemLike,
	};

	for (let i = 0; i < itemList.length; i++) {
		const li = itemList[i];
		if (li.item.toString() !== item.item.toString()) continue;
		if (li.title !== item.title) continue;

		const listItemOption = li.options
			.filter((o) => !o.is_hide_from_receipt)
			.map((o) => o.option)
			.sort()
			.join();
		const itemOption = item.options
			.filter((o) => !o.is_hide_from_receipt)
			.map((o) => o.option)
			.sort()
			.join();

		if (listItemOption !== itemOption) continue; //compare _ids of unhide option from receipt to get matched

		const listItemOfferId = li.subtotal_addons?.offer.metadata?._id ?? "";
		const listItemOfferTitle = li.subtotal_addons?.offer.metadata?.title ?? "";
		const listItemDiscountType = li.subtotal_addons?.discount.type ?? "";
		const listItemDiscountValue = li.subtotal_addons?.discount.value ?? "";
		const itemOfferId = item.subtotal_addons?.offer.metadata?._id ?? "";
		const itemOfferTitle = item.subtotal_addons?.offer.metadata?.title ?? "";
		const itemDiscountType = item.subtotal_addons?.discount.type ?? "";
		const itemDiscountValue = item.subtotal_addons?.discount.value ?? "";

		if (listItemOfferId.toString() !== itemOfferId.toString()) continue;
		if (listItemOfferTitle !== itemOfferTitle) continue;
		if (listItemDiscountType !== itemDiscountType) continue;
		if (listItemDiscountValue !== itemDiscountValue) continue;

		// All negative test failed return as duplicate
		response.isDuplicate = true;
		response.matchedIndex = i;
		response.returnItem = li;
		return response;
	}

	response.returnItem = item;
	return response;
};
